import React from "react";
import { Navigate, RouteProps, Outlet, useLocation } from "react-router-dom";
import Auth from "../../services/auth.service";



const PrivateRoute = (props: RouteProps) => {

  const uamEnvironment = process.env['REACT_APP_ENV'];
  const location = useLocation();
  const userId = Auth.getCurrentUserId();
  const tokenValid = !Auth.isTokenExpired();
  const uamtvUrl = process.env.REACT_APP_UAMTV_URL;
  const uamHomeUrl = `${uamtvUrl}`;


  // Per ora li buttiamo fuori. Il token refresh lo fa il portale legacy basandosi sulla sessione PHP se ancora attiva.

  //return userId && tokenValid ? <Outlet/> : <Navigate to="/login" replace />;


  // if (userId && tokenValid) {
  //   return <Outlet />
  // } else {
  //   window.location.href = uamHomeUrl;
  //   return <></>
  // }

  if (uamEnvironment === 'production' || uamEnvironment === 'staging') {
    if (userId && tokenValid) {
      return <Outlet />;
    } else {
      window.location.href = uamHomeUrl;
      return <></>;
    }
  } else {
    return userId && tokenValid ? <Outlet/> : <Navigate to="/login" replace />;
  }

};


export default PrivateRoute;