import React from 'react';
import Auth from '../../services/auth.service';
import { Link, Navigate } from "react-router-dom";


const Logout: React.FC = (): JSX.Element => {

  Auth.logout();
  const uamEnvironment = process.env['REACT_APP_ENV'];

  const logoutUrl = process.env.REACT_APP_UAMTV_URL + 'logout/';
  window.location.replace(logoutUrl);


  // Se NON siamo in PROD o TEST mando a login
  if(uamEnvironment !== 'production' && uamEnvironment !== 'staging') {
    return <Navigate to="/login" replace />;
  }

  return <></>;

  // return (

  //   <>

    
  //     <Navigate to="/login" replace />
  //   </>
  // );

}

export default Logout;
