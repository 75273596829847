
  // funzione per produrre stringa con minuti / ore dalla durata nei metadati 
  export function formatDuration(minutes: number) : string { 
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours > 0) {
      return `${hours}h ${remainingMinutes}min`;
    } else {
      return `${remainingMinutes}min`;
    }
  }
  // funzione calcolo percentuale
  export function calculatePercentage(moviePosition: number, movieDuration: number | null | undefined): number {
    const moviePositionInt = Math.floor(moviePosition);
    const totalDurationInSeconds = (movieDuration ?? 0) * 60; 
    const percentage = (moviePositionInt / totalDurationInSeconds) * 100;
    return percentage;
  }
  


  // usando questa al posto di console.log, i log di debug escono fuori solo sulla build di development e staging, ma non in produzione
  export function uamConsoleLog(message?: any, ...optionalParams: any[]) : void {
    if (process.env.REACT_APP_DEBUG_ENABLED === "true") {
      console.log(message, ...optionalParams);
    }
  }

  export function uamConsoleWarn(message?: any, ...optionalParams: any[]) : void {
    console.warn(message, ...optionalParams);
  }




