import AuthService from './auth.service'


export default function authHeader() {
  
  const jwt = AuthService.getJWTToken();

  if (jwt) {
    return { Authorization: 'Bearer ' + jwt }; 
  }

}