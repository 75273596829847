import axios from 'axios';
import authHeader from './auth-header';


const apiEndPointUrl = process.env['REACT_APP_API_URL'];



const treeCounterUrl = process.env['REACT_APP_TREE_COUNTER'] as string;





axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
//axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';


// All the various network calls for UAM.TV's data (movies, etc) should go as methods in this class
class UAMDataServices {

  getCategories(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/categories/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }



  getFeatured(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getFeaturedWithEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }

  getMostRecent(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getMostRecentWithEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }

  getMostViewed(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getMostViewed.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }

  getMostLiked(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getMostLiked.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }



  getEntitlements(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/entitlements/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }

  getComingSoon(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/comingsoon/list/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      }, signal: controller.signal
    });
  }


  getMovie(id: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getMovie.php?id=' + id, {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      }, signal: controller.signal
    });
  }

  getAllEvoContent(controller: AbortController = new AbortController(), top?: string) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        top: top
      },
      signal: controller.signal
    });
  }

  getClass(id: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/classes/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        id: id,
      },
      signal: controller.signal
    });
  }

  getMovieStreams(contentID: string, prop: number = 1, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/streams/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        contentID: contentID,
        prop: prop
      },
      signal: controller.signal
    });
  }

  getClassStreams(contentID: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/classes/streams/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        contentID: contentID,
      },
      signal: controller.signal
    });
  }

  getTrailerStreams(contentID: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/streams/getTrailer.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        contentID: contentID,
      },
      signal: controller.signal
    });
  }

  verifyStreamAvailability(streamUrl: string, controller: AbortController = new AbortController()) {
    return axios.head(streamUrl, { signal: controller.signal });
  }


  setFavourites(mid: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/users/favourites/post.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        mid: mid,
      },
      signal: controller.signal
    });
  }


  getFavourite(mid: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/users/favourites/getWithEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        mid: mid,
      },
      signal: controller.signal
    });
  }

  getFavourites(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/users/favourites/getWithEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }



  getUploadUrl(movieId: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/position/getUploadUrl.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        id: movieId,
      },
      signal: controller.signal
    });
  }

  getMoviePosition(movieId: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/position/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        id: movieId,
      },
      signal: controller.signal
    });
  }

  // nell'originale gestivo l'errore qui... ma ritornando la response di axios la gestione di errore va a finire da chi lo chiama...
  uploadPosition(signedUrl: string, position: number, controller: AbortController = new AbortController()) {
    return axios.put(signedUrl, position, { signal: controller.signal });
  }



  postVisualization(idMovie: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/view/post.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        idmovie: idMovie,
      },
      signal: controller.signal
    });
  }

  getLikesCount(idMovie: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/like/getCount.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        idmovie: idMovie,
      },
      signal: controller.signal
    });
  }

  toggleLike(idMovie: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/like/post.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        idmovie: idMovie,
      },
      signal: controller.signal
    });
  }

  toggleDislike(idMovie: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/dislike/post.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        idmovie: idMovie,
      },
      signal: controller.signal
    });
  }

  getMovieLike(idMovie: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/like/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        idmovie: idMovie,
      },
      signal: controller.signal
    });
  }

  getMovieDislike(idMovie: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/dislike/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        idmovie: idMovie,
      },
      signal: controller.signal
    });
  }


  // Ottengo le info di un Course
  getSingleCourse(serieId: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/courses/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        serie: serieId,
      },
      signal: controller.signal
    });
  }

  // Ottengo le info di una serie
  getSingleSerie(serieId: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/series/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        serie: serieId,
      },
      signal: controller.signal
    });
  }
  // Ottengo TUTTI gli episodi di una serie (insieme di Movie)
  getSeriesEpisodes(serieId: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/series/getEpisodes.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        serieid: serieId,
      },
      signal: controller.signal
    });
  }

  // Ottengo TUTTI gli episodi di un Course (insieme di lesson)
  getCourseEpisodes(serieId: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/courses/getEpisodes.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        serieid: serieId,
      },
      signal: controller.signal
    });
  }

  getSimilarMovies(movieId: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getSimilar.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        id: movieId,
      },
      signal: controller.signal
    });
  }

  // ritorna i similar movies per class/lesson

  getClassSimilarMovies(movieId: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getSpeakerSimilar.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        id: movieId,
      },
      signal: controller.signal
    });
  }

  // inserire il top sempre alla fine, dopo il controller
  getGratis(controller: AbortController = new AbortController(), top?: string) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getGratisWithEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        top: top,
      },
      signal: controller.signal
    });
  }

  getMoviesByCategory(catid: string, controller: AbortController = new AbortController(), top?: string) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getByCategoryWithSeries.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        catid: catid,
      },
      signal: controller.signal
    });
  }




  getRandomSelection(num: number, controller: AbortController = new AbortController(), top?: string) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getRandomSelectionWithEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        num: num,
      },
      signal: controller.signal
    });
  }



  getSuggested(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getSuggested.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      }, signal: controller.signal
    });
  }

  getHistory(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/users/history/getWithEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }

  getAllSpeakers(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/classes/list/getUniqueSpeakers.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      }, signal: controller.signal
    });
  }



  checkProjectionRequest(idMovie: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/projection/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        idmovie: idMovie,
      },
      signal: controller.signal
    });
  }

  sendProjectionRequest(idMovie: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/projection/post.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        idmovie: idMovie,
      },
      signal: controller.signal
    });
  }

  getContentByTag(tag: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getByTagWithEvo.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        tag: tag,
      },
      signal: controller.signal
    });
  }

  getContentByCast(cast: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getByCastWithSeries.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        cast: cast,
      },
      signal: controller.signal
    });
  }

  getContentBySpeaker(speaker: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/classes/list/getBySpeaker.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        speaker: speaker,
      },
      signal: controller.signal
    });
  }

  getContentByDirector(director: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/movies/onair/getByDirectorWithSeries.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        director: director,
      },
      signal: controller.signal
    });
  }

  getUserDevices(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/users/devices/get.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      }, signal: controller.signal
    });
  }


  DoDeleteDevice(devicehash: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v3/users/devices/delete.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        devicehash: devicehash,
      },
      signal: controller.signal
    });
  }

  getEasyLogin(code: number, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + '/v3/users/easylogin/post.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        code: code,
      },
      signal: controller.signal
    });
  }




  getTreeCount(controller: AbortController = new AbortController()) {
    return axios.get(treeCounterUrl, {
      // headers: {
      //   'Access-Control-Allow-Origin': '*'
      // }, 
      signal: controller.signal
    });
  }

  /**
   * 
   * @param sort {string} accetta : nameAsc (A-Z) | nameDes (Z-A) | dateAsc (meno recenti) | dateDes (più recenti) | Di default ritorna relevance
   * 
   * 
   */

  searchWithEvo(term: string, controller: AbortController = new AbortController(), sort?: string, page?: number) {
    return axios.get(apiEndPointUrl + '/v3/movies/onair/searchWithEvoPaging.php', {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      params: {
        term: term,
        sort: sort,
        page: page,
      },
      signal: controller.signal
    });
  }

  searchDrillDown(endpoint: string, controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + endpoint, {
      headers: {
        ...authHeader(),
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal
    });
  }


  // TODO rinse and repeat the above with a vast majority of 'getter' API's of all kinds


  // TODO implementare le altre chiamate 'diverse' che richiedono POST o PUT o HEAD


  pingPHPSession(controller: AbortController = new AbortController()) {
    return axios.get(apiEndPointUrl + 'v1/users/ping.php', {
      withCredentials: true,
      signal: controller.signal
    });
  }

}

const UAMServicesApi = new UAMDataServices();
export default UAMServicesApi;
