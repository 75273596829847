// TODO portare a typescript - vedi pippone qui https://www.i18next.com/overview/typescript

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'it',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });


export const uamLanguages = {
  en: { nativeName: "English" },
  it: { nativeName: "Italiano" }
}

export default i18n;

