import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../context/UserContext';

import './NavBar.css';
import { uamConsoleLog, uamConsoleWarn } from '../../../services/utils.functions';
import i18n, { uamLanguages } from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavBar: React.FC = (): JSX.Element => {

  const { t, i18n } = useTranslation();
  // accedo al contesto utente
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const uamtvUrl = process.env.REACT_APP_UAMTV_URL;
  const adminPanel = process.env.REACT_APP_ADMIN_PANEL_URL;

  const isAdmin = user.isAdmin === '1';
  const isReadOnlyOperator = user.readOnlyOperator === '1';

  const isPartner = user.partner === '1';

  let navbar = document.querySelector('.App-header');

  window.addEventListener('scroll', function () {
    // Verifica che navbar esista prima di accedere ai suoi metodi
    if (navbar) {
      // Se l'utente ha scrollato almeno 10px, aggiungi la classe 'scrolled'
      if (window.scrollY > 10) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    }
  });

  const [brakepoint1020, setBrakepoint1020] = useState(window.innerWidth <= 1020);
  const [brakepoint775, setBrakepoint775] = useState(window.innerWidth <= 775);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => {
      setBrakepoint1020(window.innerWidth <= 1020);
      setBrakepoint775(window.innerWidth <= 775);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // Reindirizza alla rotta interna con il termine di ricerca
    navigate(`/search/${searchTerm}`);
  };

  return (
    <header className="App-header">

      <div className='d-flex justify-content-between nav_font'>
        <div className="d-flex p_s_custom">
          <a href="/home">
            <img className="img-responsive" alt="UAM.TV Logo" src={process.env.REACT_APP_ASSET_URL + "img/uamtv_logo_nuovo_50h.png"} />
          </a>
        </div>
        <div className='d-flex gap-5 align-items-center'>
          {/* <button className="btn btn-outline-success" type="submit">Search</button> */}
          {/* <form className={brakepoint1020 ? 'd-none' : 'd-flex'} 
          role="search" 
          action={`${uamtvUrl}page/user/search/`} 
          method='GET'
          >
            <input className="form-control me-2" type="search" placeholder="Cerca" name="termine" aria-label="Search"></input>
            
          </form> */}
          <form
            onSubmit={handleSubmit}
            className={brakepoint1020 ? 'd-none' : 'd-flex'}
            role="search"
          >
            <input
              className="form-control me-2"
              type="search"
              placeholder="Cerca"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

          </form>
          <Link to={`/evolution`} className={brakepoint1020 ? 'd-none' : 'text-decoration-none text-white'}>
            <div className='whiteSpace uam_hover'>
              <FontAwesomeIcon className='pe-2' icon={['fas', 'graduation-cap']} />
              UAM.TV Evolution
            </div>
          </Link>
          <Link to={`/favourites`} className={brakepoint1020 ? 'd-none' : 'text-decoration-none text-white'} >
            <div className='whiteSpace uam_hover'>
              <FontAwesomeIcon className='pe-2' icon={['fas', 'star']} />
              I miei preferiti
            </div>
          </Link>

          <div className="dropdown pe-3">
            <div className="text-white dropdown-toggle user_toggle" data-bs-toggle="dropdown" aria-expanded="false" >
              {/* <FontAwesomeIcon className='pe-2' icon={['fas', 'user']} />
              {user.fname} */}
              {brakepoint1020 ? (
                <>
                  <FontAwesomeIcon className='pe-2' icon={['fas', 'magnifying-glass']} />
                  <FontAwesomeIcon className='pe-2' icon={['fas', 'graduation-cap']} />
                  <FontAwesomeIcon className='pe-2' icon={['fas', 'star']} />
                  <FontAwesomeIcon className='pe-2' icon={['fas', 'user']} />
                </>
              ) : (
                <>
                  <div className='uam_hover'>
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'user']} />
                    {user.fname}
                    <span className="ps-2">
                      <FontAwesomeIcon className='pe-2' icon={['fas', 'caret-down']} />
                    </span>
                  </div>
                </>
              )}
            </div>
            <ul className="dropdown-menu ">
              <li className={brakepoint1020 ? 'p-3' : 'd-none'}>
                {/* onSubmit={handleSearchSubmit} */}
                {/* onChange={handleSearchChange}
                value={searchTerm} */}
                {/* onClick={handleSearchSubmit} */}
                {/* <form className={brakepoint1020 ? 'd-flex' : 'd-none'} role="search" action={`${uamtvUrl}page/user/search/`} method='GET' >
                 
                  <input className="form-control me-2" type="search" placeholder="Cerca" name="termine" aria-label="Search"></input>
                  
                  <button className='align-self-center ps-3 search_icon_mobile' type='submit'>
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'magnifying-glass']} />
                  </button>

                </form> */}

                <form
                  onSubmit={handleSubmit}
                  className={brakepoint1020 ? 'd-flex' : 'd-none'}
                  role="search"
                >
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Cerca"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button className='align-self-center ps-3 search_icon_mobile' type='submit'>
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'magnifying-glass']} />
                  </button>

                </form>
              </li>
              <li className={brakepoint1020 ? 'uam_hover_bg' : 'd-none'}>
                <Link to="/evolution" className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'graduation-cap']} />
                    UAM.TV Evolution
                  </div>
                </Link>
              </li>
              <li className={brakepoint1020 ? 'uam_hover_bg' : 'd-none'}>
                <Link to="/favourites" className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'star']} />
                    I miei preferiti
                  </div>
                </Link>
              </li>
              <li className='uam_hover_bg'>
                <Link to="/history" className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'clock-rotate-left']} />
                    La mia cronologia
                  </div>
                </Link>
              </li>
              {
                user.entitlements && user.entitlements.length > 0 &&
                <li className='uam_hover_bg'>
                  <a href={`${uamtvUrl}page/user/entitlements/`} className='text-decoration-none text-dark'>
                    <div className="padding_custom py-2 whiteSpace">
                      <FontAwesomeIcon className='pe-2' icon={['fas', 'film']} />
                      I miei contenuti
                    </div>
                  </a>
                </li>
              }
              <li className='uam_hover_bg'>
                <a href={`${uamtvUrl}argomenti`} className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'tag']} />
                    Argomenti
                  </div>
                </a>
                {/* <Link to="/home" className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'tag']} />
                    Argomenti
                  </div>
                </Link> */}
              </li>
              <li className='uam_hover_bg'>
                <a href={`${uamtvUrl}events/`} className='text-decoration-none text-dark'   >
                  {/* target="_blank" rel="noopener noreferrer" */}
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'calendar']} />
                    Calendario Eventi
                  </div>
                </a>
              </li>
              <li className='uam_hover_bg'>
                <a href={`${uamtvUrl}page/user/information/`} className='text-decoration-none text-dark' >
                  {/* target="_blank" rel="noopener noreferrer" */}
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'pen-to-square']} />
                    I miei dati
                  </div>
                </a>
              </li>
              <li className='uam_hover_bg'>
                <a href={`${uamtvUrl}page/user/subscription/`} className='text-decoration-none text-dark'>
                  {/* target="_blank" rel="noopener noreferrer" */}
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'money-check-dollar']} />
                    Il mio abbonamento
                  </div>
                </a>
              </li>
              <li className='uam_hover_bg'>
                <Link to={'/devices'} className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'desktop']} />
                    I miei device
                  </div>
                </Link>
                {/* <a href={`${uamtvUrl}page/user/devices/`} className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'desktop']} />
                    I miei device
                  </div>
                </a> */}
              </li>
              <li className='uam_hover_bg'>
                <a href={`${uamtvUrl}alberi/`} className='text-decoration-none text-dark'>
                  {/* target="_blank" rel="noopener noreferrer" */}
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'tree']} />
                    I miei alberi
                  </div>
                </a>
              </li>
              <li className='uam_hover_bg'>
                <a href={`${uamtvUrl}shop/?redirectUrl=ambassador`} className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2 whiteSpace">
                    <FontAwesomeIcon className='pe-2 ' icon={['fas', 'users']} />
                    UAM.TV Ambassador
                  </div>
                </a>
              </li>
              <li className='uam_hover_bg'>
                <a href={`${uamtvUrl}shop/`} className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2">
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'shopping-cart']} />
                    UAM.TV Shop
                  </div>
                </a>
              </li>
              <li className='uam_hover_bg'>
                <Link to="/logout" className='text-decoration-none text-dark'>
                  <div className="padding_custom py-2" >
                    <FontAwesomeIcon className='pe-2' icon={['fas', 'right-from-bracket']} />
                    <span className='text_dropdown'>
                      Esci
                    </span>
                  </div>
                </Link>
              </li>
              {isPartner ? (
                <>
                  <div className='dropdown-divider'></div>
                  <li className='uam_hover_bg'>
                    {/* target="_blank" rel="noopener noreferrer" */}
                    <a href={`${uamtvUrl}partner/`} className='text-decoration-none text-dark'>
                      <div className="padding_custom py-2" >
                        <FontAwesomeIcon className='pe-2' icon={['fas', 'upload']} />
                        <span className='text_dropdown'>
                          Partner upload
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className='uam_hover_bg'>
                    {/* target="_blank" rel="noopener noreferrer" */}
                    <a href={`${uamtvUrl}producer/`} className='text-decoration-none text-dark'>
                      <div className="padding_custom py-2" >
                        <FontAwesomeIcon className='pe-2' icon={['fas', 'chart-column']} />
                        <span className='text_dropdown'>
                          Partner stats
                        </span>
                      </div>
                    </a>
                  </li>
                </>
              ) : null

              }

              {(isAdmin || isReadOnlyOperator) ? (
                <>
                  <div className='dropdown-divider'></div>
                  <li className='uam_hover_bg'>
                    <a href={adminPanel} target="_blank" rel="noopener noreferrer" className='text-decoration-none text-dark'>
                      <div className="padding_custom py-2" >
                        <FontAwesomeIcon className='pe-2' icon={['fas', 'globe']} />
                        <span className='text_dropdown'>
                          Admin Panel
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className='uam_hover_bg'>
                    <a href={`${uamtvUrl}live/?redirectUrl=admin`} target="_blank" rel="noopener noreferrer" className='text-decoration-none text-dark'>
                      <div className="padding_custom py-2" >
                        <FontAwesomeIcon className='pe-2' icon={['fas', 'globe']} />
                        <span className='text_dropdown'>
                          Live WP-Admin
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className='uam_hover_bg'>
                    <a href={`${uamtvUrl}shop/?redirectUrl=admin`} target="_blank" rel="noopener noreferrer" className='text-decoration-none text-dark'>
                      <div className="padding_custom py-2" >
                        <FontAwesomeIcon className='pe-2' icon={['fas', 'globe']} />
                        <span className='text_dropdown'>
                          Shop WP-Admin
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className=''>
                    <div className="padding_custom py-2" >
                      {Object.keys(uamLanguages).map((lang) => (
                        <button type="submit" key={lang} onClick={() => { i18n.changeLanguage(lang) }} disabled={i18n.resolvedLanguage === lang}>{lang}</button>
                      ))}
                    </div>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>

      {/* <span>API: {process.env.REACT_APP_API_URL}</span><br/>
            <span>User: {user.email}</span><br/> */}
      {/* <span>IsAdmin: {user.isAdmin}</span> <br />
            <span>ReadOnly? : {user.readOnlyOperator}</span> */}
      {/* <span>State: {user.state}</span><br/>
            <span>Evo: {user.evolution}</span><br />
            <span>Entitlements: {user.entitlements}</span> <br />
            <span>LANG : {user.language}</span>  */}

      {/* <div className="">
        {Object.keys(uamLanguages).map((lang) => (
          <button type="submit" key={lang} onClick={() => { i18n.changeLanguage(lang) }} disabled={i18n.resolvedLanguage === lang}>{lang}</button>
        ))}
      </div> */}

    </header>


  );

}

export default NavBar;

