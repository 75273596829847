import React from "react";
import { Navigate, RouteProps, Outlet } from "react-router-dom";
import Auth from "../../services/auth.service";



const PublicRoute = (props : RouteProps) => {
  
  const userId = Auth.getCurrentUserId();
  const tokenValid = !Auth.isTokenExpired();

  return userId && tokenValid ? <Navigate to="/home" replace /> : <Outlet/>;
  
};


export default PublicRoute;