import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import PrivateRoute from './Components/Routes/PrivateRoute';
import PublicRoute from './Components/Routes/PublicRoute';
import UserProvider from './context/UserContext';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';



import i18n, { uamLanguages } from './i18n';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import UAMServicesApi from './services/user.service';
import { uamConsoleLog, uamConsoleWarn } from './services/utils.functions';
import RequireAdmin from './Components/Routes/RequireAdmin';






library.add(fas, far, fab);

const Home = lazy(() => import('./pages/Home/Home'));
const History = lazy(() => import('./pages/user/History/History'));
const Favorites = lazy(() => import('./pages/user/Favorites/Favorites'));
const Devices = lazy(() => import('./pages/user/Devices/Devices'));
const Pin = lazy(() => import('./pages/user/Pin/Pin'));
const Entitlements = lazy(() => import('./pages/user/Entitlements/Entitlements'));
const Information = lazy(() => import('./pages/user/Information/Information'));
const Serie = lazy(() => import('./pages/Serie/Serie'));
const Course = lazy(() => import('./pages/Course/Course'));
const Tag = lazy(() => import('./pages/Tag/Tag'));
const Cast = lazy(() => import('./pages/Cast/Cast'));
const Speaker = lazy(() => import('./pages/Speaker/Speaker'));
const Director = lazy(() => import('./pages/Director/Director'));

const Evolution = lazy(() => import('./pages/Evolution/Evolution'));
const Free = lazy(() => import('./pages/Free/Free'));
const Category = lazy(() => import('./pages/Category/Category'));
const Search = lazy(() => import('./pages/Search/Search'));
const Info = lazy(() => import('./pages/Info/Info'));
const Infopromo = lazy(() => import('./pages/Infopromo/Infopromo'));
const Lesson = lazy(() => import('./pages/Lesson/Lesson'));
const Lessonpromo = lazy(() => import('./pages/Lessonpromo/Lessonpromo'));
const Watch = lazy(() => import('./pages/Watch/Watch'));
const Trailer = lazy(() => import('./pages/Trailer/Trailer'));
const Geolimited = lazy(() => import('./pages/Geolimited/Geolimited'));
const Error = lazy(() => import('./pages/Error/Error'))


const App: React.FC = (): JSX.Element => {

  const uamEnvironment = process.env['REACT_APP_ENV'];

  useEffect(() => {
    const controller = new AbortController();
    const intervalId = setInterval(() => {
      UAMServicesApi.pingPHPSession(controller)
        .then((res) => {
          uamConsoleLog(res, 'RISPOSTA PING PHP')
          uamConsoleLog("vengo eseguito ogni 5 minuti");
        })
        .catch((err: any) => {
          if (err.code !== "ERR_CANCELED") {
            uamConsoleWarn(err);
            //navigate(`/error`);
          } else {
            uamConsoleLog("network request cancelled");
          }
        })
      uamConsoleLog("vengo eseguito ogni 5 minuti");
    }, 300000);


    return () => {
      controller.abort();
      //Mantengo il clearInterva
      clearInterval(intervalId);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    const checkZendeskButton = setInterval(() => {
      const zendeskButton = document.getElementById('launcher');
      if (zendeskButton) {
        clearInterval(checkZendeskButton);
        if (location.pathname.startsWith('/watch') || location.pathname.startsWith('/trailer')) {
          zendeskButton.classList.add('d-none');
          // uamConsoleLog('Nascondo zendesk');
          // uamConsoleLog(document.getElementById('launcher'), 'sono zendesk e lo NASCONDO')
        } else {
          zendeskButton.classList.remove('d-none');
          // uamConsoleLog('Mostro zendesk');
          // uamConsoleLog(document.getElementById('launcher'), 'sono zendesk e lo mostro')
        }
      }
    }, 500);
    return () => clearInterval(checkZendeskButton); // clear
  }, [location.pathname]);



  return (
    <UserProvider>
      <Suspense fallback={<div>Caricamento...</div>}>
        <Routes>
          {/* Rotte solo per utenti gia' autenticati, quindi protette (private) */}
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/logout" element={<Logout />} />
            <Route path="/information" element={<Information />} />
            <Route path="/history" element={<History />} />
            <Route path="/favourites" element={<Favorites />} />
            <Route path="/devices" element={<Devices />} />
            <Route path="/pin" element={<Pin />} />



            {/* TODO Rotta commentata fino a che non gestiremo la pagina interna a React */}

            {/* RequireAdmin : Wrapper intorno alla rotta che redirige SOLO se sei Admin o ReadOnlyOperator */}
            {/* <Route path="/entitlements" element={<RequireAdmin><Entitlements /></RequireAdmin>} /> */}
            {/* <Route path="/entitlements" element={<Entitlements />} /> */}

            <Route path="/home" element={<Home />} />
            <Route path="/evolution" element={<Evolution />} />




            <Route path="/free" element={<Free />} />



            <Route path="/category/:catId" element={<Category />} />

            <Route path="/search/:searchTerm?" element={<Search />} />



            <Route path="/series/:serieId" element={<Serie />} />
            <Route path="/course/:serieId" element={<Course />} />

            <Route path="/info/:movieId" element={<Info />} />
            <Route path="/lesson/:movieId" element={<Lesson />} />

            <Route path="/infopromo/:movieId" element={<Infopromo />} />
            <Route path="/lessonpromo/:movieId" element={<Lessonpromo />} />


            <Route path="/watch/:movieId" element={<Watch />} />
            <Route path="/trailer/:movieId" element={<Trailer />} />

            <Route path="/tag/:tagName" element={<Tag />} />
            <Route path="/cast/:castName" element={<Cast />} />
            <Route path="/speaker/:speakerName" element={<Speaker />} />
            <Route path="/director/:directorName" element={<Director />} />

            {/* TODO add more routes with URL parameters such as serie, lesson, course, category, tag, etc */}

          </Route>


          {/* se gia' loggato non gli facciamo vedere il login e lo sbattiamo a /home */}
          {/* spento login dopo integrazione, si puo' usare per testing locale per ora ma non e' il modo definitivo */}



          {/* <Route path="/" element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route> */}
          {/* <Route path="/" element={<PublicRoute />}>
            <Route path="/" element={<></>} />
          </Route>     */}

           {uamEnvironment === 'production' || uamEnvironment === 'staging' ? (
            <Route path="/" element={<PublicRoute />}>
              <Route path="/" element={<></>} />
            </Route>
          ) : (
            <Route path="/" element={<PublicRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>
          )} 



          <Route path="/geolimited" element={<Geolimited />} />

          {/* MUST be last one */}
          <Route path="*" element={<Error />} />

        </Routes>
      </Suspense>
    </UserProvider>
  );
}

export default App;
