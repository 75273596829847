import { useContext, useEffect } from 'react';
import AuthService from "../../services/auth.service";
import { UserContext } from '../../context/UserContext';
import UUID from '../../types/uuid.type';


import { uamConsoleLog, uamConsoleWarn } from '../../services/utils.functions';


const UserContextLoader: React.FC = (): JSX.Element => {

    // accedo al contensto utente
    const {user, setUser} = useContext(UserContext);

    useEffect(() => {

      uamConsoleLog("UserContextLoader's useEffect (user) started");

      setUser({
        id: (new UUID(AuthService.getCurrentUserId())),
        fname: AuthService.getCurrentUserFirstName(),
        lname: AuthService.getCurrentUserLastName(),
        email: AuthService.getCurrentUserEmail(),
        state: AuthService.getCurrentUserState(),
        evolution: AuthService.getCurrentUserEvolutionFlag(),
        entitlements: AuthService.getEntitledContent(),
        language: AuthService.getCurrentUserLanguage(),
        issues: AuthService.getIssues(),
        isAdmin: AuthService.getIsAdmin(),
        isAmbassador: AuthService.getAmbassadorId(),
        readOnlyOperator: AuthService.getReadOnlyOperator(),
        partner: AuthService.getPartner(),
      })


      return () => {
        uamConsoleLog("UserContextLoader's useEffect (user) cleanup function");
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
      <></>
    );

}

export default UserContextLoader;