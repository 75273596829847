import {Component} from 'react';
import AuthService from "../../../../services/auth.service";
import { IUserContext } from '../../../../context/UserContext';
import Button from 'react-bootstrap/Button';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {Navigate} from 'react-router-dom';


import './LoginForm.css';

type Props = {};

type State = {
  currentUser: IUserContext | undefined,
  redirect: string | null,
  username: string,
  password: string,
  loading: boolean,
  message: string
};



export default class LoginForm extends Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.doLogin = this.doLogin.bind(this);
  
      this.state = {
        currentUser: undefined,
        redirect: null,
        username: "",
        password: "",
        loading: false,
        message: ""
      };
    }


    componentDidMount() {
        const currentUserId = AuthService.getCurrentUserId();

        if (currentUserId) {
            this.setState({ redirect: "/home" });
        };
    }
    


    validationSchema() {
        return Yup.object().shape({
          email: Yup
            .string()
            .required("This field is required!")
            .min(5, 'email must be at least 5 characters'),
          password: Yup
            .string()
            .required("This field is required!")
            .min(8, 'password must be at least 8 characters')
        });
    }


    doLogin(formValue: { email: string; password: string }) {

        const { email, password } = formValue;

        this.setState({
          message: "",
          loading: true
        });

        AuthService.login(email, password)
            .then(

                () => {
                    this.setState({
                      redirect: "/home"
                    });
                  },
                  error => {
                    const resMessage =
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString();
            
                    this.setState({
                      loading: false,
                      message: resMessage
                    });
                  }


            )
            .catch()
            .finally();

        

    }



    render() {
        if (this.state.redirect) {
          return <Navigate to={this.state.redirect} />
        }
    
        const { loading, message } = this.state;
    
        const initialValues = {
          email: "",
          password: "",
        };

        return(
            <div className='loginFormContainer row mt-10'>
              <h2>{process.env.REACT_APP_TITLE} Login</h2>
              <div className='col-3'></div>
              <div className='col-6'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.doLogin}
                    >
                        {({ errors, touched, resetForm }) => (
                            <Form className='loginForm' data-testid="loginForm">
                                <div className='row'>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <Field
                                            name="email"
                                            type="email"
                                            placeholder='email@dominio.it'
                                            className={
                                                'form-control' +
                                                (errors.email && touched.email ? ' is-invalid' : '')
                                            }
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <Field
                                            name="password"
                                            type="password"
                                            placeholder='password'
                                            className={
                                                'form-control' +
                                                (errors.password && touched.password ? ' is-invalid' : '')
                                            }
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <div className='row mt-15'>
                                    <Button className='loginSubmitButton' id='loginSubmitButton' type='submit' disabled={loading}>Login</Button>
                                </div>
                            </Form>
                        )}
                </Formik>
            </div>
            <div className='col-3'></div>
            </div>
        );
    }
};
