import React from 'react';
import LoginForm from '../../Components/UXComponents/Forms/LoginForm/LoginForm' ;
import NavBar from '../../Components/UXComponents/Elements/NavBar';
import Auth from '../../services/auth.service';

import './Login.css';
import UserContextLoader from '../../Components/UserContextLoader/UserContextLoader';


const Login: React.FC = (): JSX.Element => {

  Auth.logout();

    return (
      <main className="Login">

        <UserContextLoader/>
        <NavBar/>
        
        <section>
          <LoginForm/>
        </section>
        
      </main>
    );
}

export default Login;
