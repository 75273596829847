import { ReactNode, createContext, useState, Dispatch, SetStateAction } from "react";
import UUID from "../types/uuid.type";
import AuthService from "../services/auth.service";


export enum subState {
    Active = "Active",
    Suspended = "Suspended",
    Inactive = "Inactive",
    Cancelled = "Cancelled",
}

// TODO come migliorare la validazione del formato?
export type User = {
    id: UUID | undefined, 
    email: string | undefined,
    fname?: string | undefined,
    lname?: string | undefined,
    state: subState,
    evolution: number,
    entitlements: string[],
    language: string | undefined,
    issues: boolean | undefined,
    isAdmin: any, // togliere any, renderlo più specifico
    readOnlyOperator: any,
    partner: any,
    isAmbassador: any,
}

export interface IUserContext {
    user: User,
    setUser: Dispatch<SetStateAction<User>>
}



const defaultUserContext = {
    user: {
        id: (new UUID(AuthService.getCurrentUserId())),
        fname: AuthService.getCurrentUserFirstName(),
        lname: AuthService.getCurrentUserLastName(),
        email: AuthService.getCurrentUserEmail(),
        state: AuthService.getCurrentUserState(),
        evolution: AuthService.getCurrentUserEvolutionFlag(),
        entitlements: AuthService.getEntitledContent(),
        language: AuthService.getCurrentUserLanguage(),
        issues: AuthService.getIssues(),
        isAdmin: AuthService.getIsAdmin(),
        isAmbassador: AuthService.getAmbassadorId()
    },
    setUser: (user: User) => {}
} as IUserContext;


export const UserContext = createContext(defaultUserContext);

type UserProviderProps = {
    children: ReactNode
}

export default function UserProvider({children} : UserProviderProps) {

    // default initializer
    const [user, setUser] = useState<User>({ 
        id: undefined,
        fname: undefined,
        lname: undefined,
        email: undefined,
        state: subState.Inactive,
        evolution: 0,
        entitlements: [],
        language: 'it',
        issues: undefined,
        isAdmin: undefined,
        readOnlyOperator: undefined,
        partner: undefined,
        isAmbassador: undefined,
    });

    return(
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    );
}