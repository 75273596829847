import axios from "axios";
import Cookies from 'js-cookie';
import { uamConsoleLog, uamConsoleWarn } from "./utils.functions";


const apiEndPointUrl = process.env['REACT_APP_API_URL'];
const uamEnvironment = process.env['REACT_APP_ENV'];

class AuthService {
  
  login = (email: string, password: string) => {

    const loginApiEndPoint : string = apiEndPointUrl + 'v3/users/auth/login.php';

    let formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);


    // POST di semplice formdata funziona anche da localhost. 
    // invece le GET dell'altra classe per i dati hanno il problema di CORS
    return axios.post(loginApiEndPoint, formData, {})
    .then((res) => { 
        uamConsoleLog(res);
        if (uamEnvironment === 'development' || uamEnvironment === 'testing') {
          //uamConsoleLog( uamEnvironment,'salvo nel localstorage')
          localStorage.setItem("uamtoken", JSON.stringify(res.data)); 
        } else {
          uamConsoleLog('salvo nei cookie, TEST')
          Cookies.set("uam-auth", JSON.stringify(res.data), { path: '/', domain: '.uam.tv', secure: true });
        }
        
        return res.data; 
        
    })
    .catch((err) => { 
        uamConsoleWarn(err);
    });

  }


  logout() {

    if (uamEnvironment === 'development' || uamEnvironment === 'testing') {
      //uamConsoleLog(uamEnvironment, 'salvo nel localstorage')
      localStorage.removeItem("uamtoken"); 
    } else {
      Cookies.remove("uam-auth", { path: '/', domain: '.uam.tv', secure: true });
    }

  }


  getTokenPayload() {

    const jwt = this.getJWTToken();

    if (jwt !== null) {

      const base64Url = jwt.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return jsonPayload;
    }

    return null;
  }


  getCurrentUserId() {

    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).userId;
    }

    return null;

  }


  getCurrentUserEmail() {

    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).userName;
    }

    return null;

  }

  getCurrentUserFirstName() {

    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).fname;
    }

    return null;

  }

  getCurrentUserLastName() {

    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).lname;
    }

    return null;

  }

  getCurrentUserLanguage() {
    const jsonPayload = this.getTokenPayload();
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).lang;
    }
    return null;
  }

  getIsAdmin() {
    const jsonPayload = this.getTokenPayload();
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload)['is-admin'];
    }
    return null;
  }

  getAmbassadorId() {
    const jsonPayload = this.getTokenPayload();
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload)['ambassador-id'];
    }
    return null;
  }

  getReadOnlyOperator() {
    const jsonPayload = this.getTokenPayload();
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload)['readonly-operator'];
    }
    return null;
  }

  getPartner() {
    const jsonPayload = this.getTokenPayload();
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).partner;
    }
    return null;
  }

  getIssues() {
    const jsonPayload = this.getTokenPayload();
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).issues;
    }
    return null;
  }

  parseJWTOutOfString(userStr: string) : string {
    const userJson = JSON.parse(userStr);
    const jwt = userJson['jwt'];
    return jwt;
  }


  getJWTToken() {
    
    if (uamEnvironment ==='development' || uamEnvironment ==='testing') {
      //uamConsoleLog(uamEnvironment.toString() , 'salvo nel localstorage')
      const userStr = localStorage.getItem("uamtoken"); 
      if (userStr) {
        return this.parseJWTOutOfString(userStr);
      }
    } else {
      const userStr = Cookies.get("uam-auth");
      if (userStr) { 
        return this.parseJWTOutOfString(userStr);
      }
    }

    return null;

  }
  
  
  getCurrentUserState() {
    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      const currentSubState = JSON.parse(jsonPayload).state;
      if (currentSubState !== null && currentSubState !== "") {
        return JSON.parse(jsonPayload).state;
      }
    }

    return "Inactive";
  }


  getCurrentUserEvolutionFlag() : number {
    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).evolution;
    }

    return 0;
  }



  getSubscriptionIssues() {

    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).issues;
    }

    return null;
  }
      


  getEntitledContent() {

    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).entitledcontent;
    }

    return [];
  }


  getEntitledStreams() {

    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {
      return JSON.parse(jsonPayload).entitledstreams;
    }

    return [];
  }

  
  isUserEntitledForContent(contentId: string) : boolean {
    return this.getEntitledContent().includes(contentId);
  }


  isTokenExpired() : boolean {

    const jsonPayload = this.getTokenPayload();
    
    if (jsonPayload !== null) {

      const exp = JSON.parse(jsonPayload).exp;
      const expMs = exp * 1000;
      //const expMsEarlier = expMs - 600000; // 10 minuti di offset per eventuali disallineamenti di clock dei device
      const now = new Date().getTime();

      return (now >= expMs); 

    }

    return true;
  }


}

const Auth = new AuthService();
export default Auth; 
